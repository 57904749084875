<template>
  <div class="ui segment">
    <h4>
      Changelog
    </h4>
    <p>
      Letzte Änderung: {{ formatDate(dateOfLastChange) }}
      <br>
      Aktuelle Version: {{ formatCurrentVersion(dateOfLastChange) }}
    </p>
    <br>

    <sui-grid v-for="changelog in allChangelogEntries" :key="changelog.id" textAlign="top">
      <sui-grid-row :columns="2" v-for="entry in changelog.entries" :key="entry.id">
        <sui-grid-column :width="6" class="text-left p-05">
          <sui-label>
            {{ entry.changed_function }}
          </sui-label>
        </sui-grid-column>
        <sui-grid-column :width="10" class="text-left">
          <h5 class="p-0 m-0">{{ entry.header }}</h5>
          {{ entry.description }}
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>

  </div>

</template>

<script>
import store from "../store";

export default {
  name: "Changelog",
  computed: {
    // Is constructed as an array of objects, where each object has the following structure:
    // { date: YYYY-MM-DD HH:MM:SS, entries: allChangelogEntriesOfOneDateArray }
    allChangelogEntries() {
      let allChangelogEntriesArray = [];
      let current_changelog_entry_version;
      var allChangelogEntriesOfOneDateArray = [];

      if (store.state.changelog.allChangelogEntries) {
        store.state.changelog.allChangelogEntries.forEach(function (e) {
          let currentChangelogEntry = {};

          // Loop has reached the changelog entries of another date
          if (current_changelog_entry_version !== e.current_version) {
            allChangelogEntriesOfOneDateArray = [];
            currentChangelogEntry.date = e.current_version;
            // Here, a reference to the currentChangelogEntry object is put into the allChangelogEntriesArray (but not the values of the object themselves)
            allChangelogEntriesArray.push(currentChangelogEntry);
          }

          allChangelogEntriesOfOneDateArray.push(e);
          currentChangelogEntry.entries = allChangelogEntriesOfOneDateArray;
          current_changelog_entry_version = e.current_version;
        });

        return allChangelogEntriesArray;
      }
      else {
        return [];
      }
    },
    dateOfLastChange() {
      var date_of_last_change;

      function reverse(array) {
        return array.map((item, idx) => array[array.length - 1 - idx])
      }

      if (store.state.changelog.allChangelogEntries) {
        var revertedArray = reverse(store.state.changelog.allChangelogEntries);
        revertedArray.forEach(function (e) {
          date_of_last_change = e.current_version;
        })
        return date_of_last_change;
      }
      else {
        return "";
      }
    }
  },
  created() {
    store.dispatch('changelog/getAllChangelogEntries');
  },
  methods: {
    formatDate: function (iso_date) {
      if (iso_date) {
        var dateAndHoursArray = iso_date.split(' ');
        var dateArray = dateAndHoursArray[0].split("-");
        return dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0];
      }
      else {
        return '';
      }
    },
    formatCurrentVersion: function (iso_date) {
      if (!iso_date) {
        return '';
      }
      else {
        var dateAndHoursArray = iso_date.split(' ');
        var dateArray = dateAndHoursArray[0].split("-");
        var timeArray = dateAndHoursArray[1].split(":");
        return dateArray[0] + '.' + dateArray[1] + '.' + dateArray[2] + '.' + timeArray[0] + timeArray[1] + timeArray[2];
      }
    }
  }
}
</script>
